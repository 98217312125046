function showPopup() {
    var popUp = $('.pop-up'),
        closePopUp = $('.pop-up__close'),
        isRemoved = false;

    // Click handlers
    $(closePopUp).click(function () {
        popUp.removeClass('active');
        isRemoved = true;

        if (Cookies.get('popup-first-click') == 'true') {
            Cookies.set('popup-second-click', 'true');
        } else {
            Cookies.set('popup-first-click', 'true');
        }
    });

    $(window).scroll(function () {
        if ($(window).scrollTop() > 500) {
            if (Cookies.get('popup-second-click') != 'true') {
                if (Cookies.get('popup-first-click') != 'true') {
                    popUp.addClass('active');
                    isRemoved = true;
                } else {
                    if (isRemoved !== true) {
                        isRemoved = true;

                        setTimeout(function () {
                            popUp.addClass('active');
                        }, 10000);
                    }
                }
            }
        }
    });
}
