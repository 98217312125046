(function ($) {
	// encapsulate variables that need only be defined once
	var pl = /\+/g,  // Regex for replacing addition symbol with a space
		searchStrict = /([^&=]+)=+([^&]*)/g,
		searchTolerant = /([^&=]+)=?([^&]*)/g,
		decode = function (s) {
			return decodeURIComponent(s.replace(pl, " "));
		};

	// parses a query string. by default, will only match good k/v pairs.
	// if the tolerant option is truthy, then it will also set keys without values to ''
	$.parseQuery = function(query, options) {
		var match,
			o = {},
			opts = options || {},
			search = opts.tolerant ? searchTolerant : searchStrict;

		if ('?' === query.substring(0, 1)) {
			query  = query.substring(1);
		}

		// each match is a query parameter, add them to the object
		while (!!(match = search.exec(query))) {
			o[decode(match[1])] = decode(match[2]);
		}

		return o;
	};

	// parse this URLs query string
	$.getQuery = function(options) {
		return $.parseQuery(window.location.search, options);
	};

    $.fn.parseQuery = function (options) {
        return $.parseQuery($(this).serialize(), options);
    };
}(jQuery));

function youtubeVideoClick() {
	// JavaScript to be fired on the home page, after the init JS
	$('a[href^="http://www.youtube.com"]').on('click', function(e){
		// Store the query string variables and values
		// Uses "jQuery Query Parser" plugin, to allow for various URL formats (could have extra parameters)
		var queryString = $(this).attr('href').slice( $(this).attr('href').indexOf('?') + 1);
		var queryVars = $.parseQuery( queryString );

		// console.log(queryVars);

		// if GET variable "v" exists. This is the Youtube Video ID
		if ( 'v' in queryVars )
		{
			// Prevent opening of external page
			e.preventDefault();

			// Variables for iFrame code. Width and height from data attributes, else use default.
			var vidWidth = 960; // default
			var vidHeight = 540; // default
			var videoId = queryVars.v;

			if ( $(this).attr('data-width') ) { vidWidth = parseInt($(this).attr('data-width')); }
			if ( $(this).attr('data-height') ) { vidHeight =  parseInt($(this).attr('data-height')); }
			var iFrameCode = '<div class="embed-responsive embed-responsive-16by9"><iframe width="1280" height="720" scrolling="no" allowtransparency="true" allowfullscreen="true" src="https://www.youtube.com/embed/'+  videoId + '?rel=0&wmode=transparent&showinfo=0&autoplay=1" frameborder="0"></iframe></div>';

			// Replace Modal HTML with iFrame Embed
			$('.modal .modal-body').html(iFrameCode);

			// Open Modal
			$('.modal').modal();
		}
	});

	// Clear modal contents on close.
	// There was mention of videos that kept playing in the background.
	$('.modal').on('hidden.bs.modal', function () {
		$('.modal .modal-body').html('');
	});
}
