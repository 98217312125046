function footerExpand() {
    $(".footer__col").find("header").click(function () {
        var header = $(this);
        var ww = $(window).width();
        if (ww < 768) {
            header.toggleClass("header-expanded").parent().toggleClass("col-expanded");
            var content = $(this).parent().find(".footer__col-content");
            content.slideToggle(400, function () {
                content.toggleClass("expanded");
            });
        }
    });
}

function acceptCookie() {

    $('.cookie-bar').on('click', '.button', function(ev) {
        ev.preventDefault();

        Cookies.set('cookie-accept', 'accepted', { expires: 365 });
        $('.cookie-bar').fadeOut(500);
    });

    if (Cookies.get('cookie-accept') !== 'accepted') {
        $('.cookie-bar').fadeIn(250);
    }

}
