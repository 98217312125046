function initMatchingHeight(element) {
    $(element).matchHeight({
        'byRow': true
    });
}

function initMinHeight(element, target) {
    $(element).matchHeight({
        'byRow': true,
        'property': 'min-height',
        'target': $(target)
    });
}