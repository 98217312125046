// Fancybox
function fancybox() {
	$('.fancybox, .fusion-lightbox').attr('rel', 'gallery');

	$('.fancybox, .fusion-lightbox').fancybox({
		openEffect  : 'none',
		closeEffect : 'none'
	});
}

// Fancybox media
function fancyboxMedia() {
	$('.fancybox-media').attr('rel', 'media-gallery');

	$('.fancybox-media').fancybox({
		openEffect  : 'none',
		closeEffect : 'none',
		helpers : {
			media : {}
		}
	});
}
