function initRelatedSlider() {

    if (($(window).width() > 767) && ($('.related-list--slider').length > 0)) {

        if (!$('.related-list--slider ul').hasClass('slick-initialized')) {
            $('.related-list--slider ul').slick({
                vertical: true,
                verticalSwiping: true,
                slidesToShow: 10,
                slidesToScroll: 3,
                infinite: true,
                dots: false,
                prevArrow: '',
                nextArrow: '<div class="related__next"><div class="button button--small"><i class="fa fa-angle-down"></i></div></div>',
                responsive: [
                    {
                        breakpoint: 1220,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3
                        }
                    }
                ]
            });
        }
    }
}