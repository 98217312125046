function socialWall() {
	var $container = $('.socialwall-holder');

	$container.imagesLoaded(function(){
		$container.isotope({
			itemSelector: '.social-item',
			layoutMode: 'packery',
		});
	});

	$container.infinitescroll({
		navSelector  : '.social-nav',    // selector for the paged navigation
		nextSelector : '.social-nav a',  // selector for the NEXT link (to page 2)
		itemSelector : '.social-item',     // selector for all items you'll retrieve
		loading: {
			msgText: '<i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>',
			finishedMsg: 'No more post to load.'
		}
	},
		// trigger Masonry as a callback
		function( newElements ) {
			// hide new items while they are loading
			var $newElems = $( newElements ).css({ opacity: 0 });
			// ensure that images load before adding to masonry layout
			$newElems.imagesLoaded(function(){
				// show elems now they're ready
				$newElems.animate({ opacity: 1 });
				$container.isotope( 'appended', $newElems, true );
			});
		}
	);

}
