/* =============================================
 MOBILE NAV
 ================================================ */

function mobileNav() {
    $('.nav-trigger-holder').on('click', function () {
        $(this).toggleClass('open');
        $(this).find('.hamburger').toggleClass('open');
        $('.navbar-nav').stop().toggleClass('open');

        $('.search-trigger').removeClass('open');
        $('.search-form-holder').slideUp(300);
    });

    $('.submenu-toggle').on('click', function () {
        $(this).siblings('.nav-main-sub').stop().addClass('open');
    });

    $('.submenu-close').on('click', function () {
        $(this).parents('.nav-main-sub').stop().removeClass('open');
    });
}

/* =============================================
 SEARCH
 ================================================ */
function searchTrigger() {
    $('.search-trigger').on('click', function () {
        $(this).stop().toggleClass('open');

		$('.search-form-holder').stop().slideToggle(300);

        $('.nav-trigger-holder').stop().removeClass('open');
        $('.nav-trigger-holder').find('.hamburger').stop().removeClass('open');
        $('.navbar-nav').stop().removeClass('open');
        $('.nav-main-sub').stop().removeClass('open');
    });
}

/* =============================================
 SIDEBAR
 ================================================ */
function sidebarTrigger() {
    $('.side-trigger').on('click', function () {
        // $(this).toggleClass('open');
        // $(this).find('.hamburger').toggleClass('open');
        $('.site-sidebar').stop().addClass('open');
        $('html').stop().addClass('no-scroll');
    });

    $('.site-sidebar__close').on('click', function () {
        $('.site-sidebar').stop().removeClass('open');
        $('html').stop().removeClass('no-scroll');
    });
}

/* =============================================
 FIXEDHEADER
 ================================================ */
function fixedheader() {
    if ($(window).width() > 992) {

        var distance = $('.site-header__navbar').offset().top + 1;
        $(window).scroll(function () {
            if ($(this).scrollTop() >= distance) {
                $('.site-header').addClass('sticky').css({
                    'position': 'fixed',
                    'top': '-' + distance + 'px'
                });
            } else {
                $('.site-header').removeClass('sticky').css({
                    'position': 'absolute',
                    'top': 0
                });
            }
        });

    }
}

/* =============================================
 SUBMENU OVERLAY
 ================================================ */

function submenuOverlay() {
	if ($(window).width() > 992) {
	    $('.hasschildren').hover(function () {
	        $('.submenu-overlay').addClass('active');
	    }, function () {
	        $('.submenu-overlay').removeClass('active');
	    });
	}
}

/* =============================================
 LOGO ANIMATION
 ================================================ */
function logoAnimation() {

	setTimeout(function() {
		Cookies.set('visited', 'true');
	}, 300);


    if (Cookies.get('visited') !== 'true' ) {
		setTimeout(function() {
        	$('.site-header__logo img').addClass('animate');
		}, 300);
	}

}
