function scrollBarShow() {

    $(document).scroll(function() {
        var y = $(this).scrollTop();
        if (y > 100) {
            $('body').addClass('scrollbar-visible');
        } else {
            $('body').removeClass('scrollbar-visible');
        }
    });

}
