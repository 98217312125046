var headerHeight = $('.site-header').outerHeight();

// Flexscroll
function flexScroll() {
    $('.flex--scroll').on('click', function (e) {
        e.preventDefault();
        var target = $(this).parents('.flex-content').next();

        $('html, body').animate({
            scrollTop: $(target).offset().top - headerHeight
        }, 1000);

    });
}

// Scroll by select
function scrollSelect() {
    $('#book-scroll').on('change', function () {
        var target = '#' + $(this).val();

        $('html, body').animate({
            scrollTop: $(target).offset().top - headerHeight
        }, 1000);
    });
}

function scrollNext() {
    $('.scroll-next').on('click', function (e) {
            e.preventDefault();

            var target = $(this).attr('data-link'),
                scrollOffset = 0;

            if ($(window).width() <= 992) {
                scrollOffset = $(target).offset().top - headerHeight - 16; // 16 substracted for headers
            } else {
                scrollOffset = $(target).offset().top - headerHeight + 95; // 16 substracted for headers
            }

            $('html, body').animate({
                scrollTop: scrollOffset
            }, 1000);
        }
    );
}
