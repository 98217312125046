function initAbcToggle() {
    $('.abc-result__header').click(function () {
        $(this).parents('.abc-result').toggleClass('active').children('.abc-result__body').slideToggle(300);
    });
}

function initAbcFilter() {

    $('.abc-list__anchor').click(function () {
        if (!$(this).hasClass('active')) {
            var $letter = $(this).attr('data-letter');

            $(".abc-result").fadeOut(200);
            $(".abc-result[data-letter='" + $letter + "']").fadeIn();

            $('.abc-list__anchor.active').removeClass('active');
            $(this).addClass('active');
        }

        return false;
    });
}