/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

function windowresize() {
    $(window).resize(function () {
        fixedheader();
        submenuOverlay();
        initRelatedSlider();
        subjectBar();
    });
}

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                // JavaScript to be fired on all pages
                windowresize();
                footerExpand();
                youtubeVideoClick();
                mobileNav();
                searchTrigger();
                sidebarTrigger();
                fixedheader();
                submenuOverlay();
                initRelatedSlider();
                acceptCookie();
				logoAnimation();
                preventEmptySearch();
                registerDynamicGaEvents();
                registerClickGaEvents();
                showPopup();

                if ($('.scrollbar').length > 0) {
                    scrollBarShow();
                }

				if ($('.socialwall-holder').length > 0) {
					socialWall();
				}

                if ($('.scroll-next').length > 0) {
                    scrollNext();
                }

                if ($('.subjects-bar').length > 0) {
                    subjectBar();
                }

                if ($('.fancybox, .fusion-lightbox').length > 0) {
                    fancybox();
                }

                if ($('.fancybox-media').length > 0) {
                    fancyboxMedia();
                }

                if ($('.sidebar-item').length > 0) {
                    initMatchingHeight('.sidebar-item');
                }

                if ($('.sidebar-item').length > 0) {
                    initMatchingHeight('.sidebar-item');
                }

                if ($('.item').length > 0) {
                    initMatchingHeight('.item');
                }

                if ($('.matchHeight').length > 0) {
                    initMatchingHeight('.matchHeight');
                }

                if ($('#book-scroll').length > 0) {
                    scrollSelect();
                }
            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function () {
            },
            finalize: function () {
            }
        },
        'archive': {
            init: function () {
            }
        },
        'single': {
            init: function () {
                loadRelatedBlogPosts();
                showReadProgress();
            }
        },
        'page_template_flexible_content': {
            init: function () {
                flexScroll();
            }
        },
        'page_template_abc': {
            init: function () {
                initAbcToggle();
                initAbcFilter();
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
