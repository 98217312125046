/* ========================================================================
 GOOGLE ANALYTICS EVENTS
 * ======================================================================== */
function registerDynamicGaEvents() {
    // Dynamic click events
    $(document).on('click', '[data-event-category]', function () {

        var dataEventCategory = $(this).data('event-category'),
            dataEventLabel = $(this).data('event-label');

        ga('send', 'event', dataEventCategory, dataEventLabel);
    });
}

function registerClickGaEvents() {
    // App download footer
    $('.app-badge--footer-apple').click(function () {
        ga('send', 'event', 'button', 'click', 'footer, download apple');
    });

    $('.app-badge--footer-android').click(function () {
        ga('send', 'event', 'button', 'click', 'footer, download google');
    });

    $('.app-badge--footer-windows').click(function () {
        ga('send', 'event', 'button', 'click', 'footer, download windows');
    });

    // App download
    $('.cta-app__iphone').click(function () {
        ga('send', 'event', 'button', 'click', 'download apple');
    });

    $('.cta-app__google').click(function () {
        ga('send', 'event', 'button', 'click', 'download google');
    });

    $('.cta-app__windows').click(function () {
        ga('send', 'event', 'button', 'click', 'download windows');
    });

    // Bestel Online Guide
    $('.oei-online-guide .button--next').click(function () {
        ga('send', 'event', 'button', 'click', 'bestel guide');
    });

    // Bestel boek direct
    $('#de-tien-sprongen-in-de-mentale-ontwikkeling-van-je-baby .button--bc3.button--next').click(function () {
        ga('send', 'event', 'button', 'click', 'direct, boek sprongen');
    });

    $('#oei-ik-groei-weetjes-en-mijlpalen .button--bc3.button--next').click(function () {
        ga('send', 'event', 'button', 'click', 'direct, boek mijlpalen');
    });

    $('#de-beste-tips-per-sprong-in-het-nieuwe-speel-en-doeboek .button--bc3.button--next').click(function () {
        ga('send', 'event', 'button', 'click', 'direct, boek doeboek');
    });

    $('#oei-ik-groei-buik-en-babygeheimen .button--bc3.button--next').click(function () {
        ga('send', 'event', 'button', 'click', 'direct, boek geheimen');
    });

    $('#OeijijgroeitEerstejaardagboek .button--bc3.button--next').click(function () {
        ga('send', 'event', 'button', 'click', 'direct, dagboek 1jr');
    });

    $('#OeiwijgroeienNegenmaandendagboek .button--bc3.button--next').click(function () {
        ga('send', 'event', 'button', 'click', 'direct, dagboek 9mnd');
    });

    $('#oei-ik-groei-fotokaarten .button--bc3.button--next').click(function () {
        ga('send', 'event', 'button', 'click', 'direct, fotokaarten');
    });

    // Bestel boek sprongen
    $('.post-template-book-single.oei-ik-groei .book-details .button.button--bc3').click(function () {
        ga('send', 'event', 'button', 'click', 'detail, boek sprongen');
    });

    // Bestel boek mijlpalen
    $('.post-template-book-single.weetjes-en-mijlpalen .book-details .button.button--bc3').click(function () {
        ga('send', 'event', 'button', 'click', 'detail, boek mijlpalen');
    });

    // Bestel doeboek
    $('.post-template-book-single.speel-en-doekboek .book-details .button.button--bc3').click(function () {
        ga('send', 'event', 'button', 'click', 'detail, boek doeboek');
    });

    // Bestel boek babygeheimen
    $('.post-template-book-single.oei-ik-groei-buik-en-babygeheimen .book-details .button.button--bc3').click(function () {
        ga('send', 'event', 'button', 'click', 'detail, boek geheimen');
    });

    // Bestel dagboek 1jr
    $('.post-template-book-single.oei-jij-groeit-dagboek .book-details .button.button--bc3').click(function () {
        ga('send', 'event', 'button', 'click', 'detail, dagboek 1jr');
    });

    // Bestel dagboek 9mnd
    $('.post-template-book-single.oei-wij-groeien-dagboek .book-details .button.button--bc3').click(function () {
        ga('send', 'event', 'button', 'click', 'detail, dagboek 9mnd');
    });

    // Bestel fotokaarten
    $('.post-template-book-single.fotokaarten .book-details .button.button--bc3').click(function () {
        ga('send', 'event', 'button', 'click', 'detail, fotokaarten');
    });

    // Sprongen
    $('.category-mentale-sprongen .tool--geboortedatum .button').click(function () {
        ga('send', 'event', 'button', 'click', 'sprongen, datum berekenen');
    });

    $('.category-mentale-sprongen .item--tool--uitrekendatum .button').click(function () {
        ga('send', 'event', 'button', 'click', 'sprongen, nieuwsbrief');
    });

    // Artikelen Kinderwens
    $('.category-kinderwens .item--tool--uitrekendatum .button').click(function () {
        ga('send', 'event', 'button', 'click', 'kinderwens, datum berekenen');
    });

    // Artikelen Zwanger
    $('.category-zwanger .item--tool--uitrekendatum .button').click(function () {
        ga('send', 'event', 'button', 'click', 'zwanger, datum berekenen');
    });

    $('.category-zwanger .item--tool--mail .button').click(function () {
        ga('send', 'event', 'button', 'click', 'zwanger, nieuwsbrief');
    });

    // Artikelen Newborn
    $('.category-newborn .item--tool--uitrekendatum .button').click(function () {
        ga('send', 'event', 'button', 'click', 'newborn, datum berekenen');
    });

    $('.category-newborn .item--tool--mail .button').click(function () {
        ga('send', 'event', 'button', 'click', 'newborn, nieuwsbrief');
    });

    // Artikelen Baby
    $('.category-baby .item--tool--uitrekendatum .button').click(function () {
        ga('send', 'event', 'button', 'click', 'baby, datum berekenen');
    });

    $('.category-baby .item--tool--mail .button').click(function () {
        ga('send', 'event', 'button', 'click', 'baby, nieuwsbrief');
    });

    // Artikelen dreumes
    $('.category-dreumes .item--tool--uitrekendatum .button').click(function () {
        ga('send', 'event', 'button', 'click', 'dreumes, datum berekenen');
    });

    $('.category-dreumes .item--tool--mail .button').click(function () {
        ga('send', 'event', 'button', 'click', 'dreumes, nieuwsbrief');
    });

    // Artikelen jij en je gezin
    $('.category-jij-en-je-gezin .item--tool--uitrekendatum .button').click(function () {
        ga('send', 'event', 'button', 'click', 'jij en gezin, datum berekenen');
    });

    $('.category-jij-en-je-gezin .item--tool--mail .button').click(function () {
        ga('send', 'event', 'button', 'click', 'jij en gezin, nieuwsbrief');
    });

    // Blog
    $('.category-gastbloggers .item--tool--uitrekendatum .button').click(function () {
        ga('send', 'event', 'button', 'click', 'blog, datum berekenen');
    });

    $('.category-gastbloggers .item--tool--mail .button').click(function () {
        ga('send', 'event', 'button', 'click', 'blog, nieuwsbrief');
    });

    // Tool uitgerekende datum
    $('.ovulatiecalculator .tool--ovulatiekalender .button').click(function () {
        ga('send', 'event', 'button', 'click', 'vruchtbare periode berekenen');
    });

    $('.ovulatiecalculator .updates .button').click(function () {
        ga('send', 'event', 'button', 'click', 'vruchtbare periode, nieuwsbrief');
    });

    // Tool vruchtbare periode
    $('.uitgerekende-datum-berekenen .tool--geboortedatum .button').click(function () {
        ga('send', 'event', 'button', 'click', 'uitgerekende datum berekenen');
    });

    $('.uitgerekende-datum-berekenen .updates .button').click(function () {
        ga('send', 'event', 'button', 'click', 'uitgerekende datum, nieuwsbrief');
    });

    // Tool geslachtsbepaling
    $('.geslachtsbepaling .tool--geslachtsbepaling .button').click(function () {
        ga('send', 'event', 'button', 'click', 'uitslag geslachtsbepaling');
    });

    $('.geslachtsbepaling .updates .button').click(function () {
        ga('send', 'event', 'button', 'click', 'geslachtsbepaling, nieuwsbrief');
    });

    // Tool hoe groot is je baby
    $('.hoe-groot-is-je-baby .updates .button').click(function () {
        ga('send', 'event', 'button', 'click', 'grootte baby, nieuwsbrief');
    });

    // Bestel oortjes onder water
    $('.oortjes-onder-water-ebook .book-details .button').click(function () {
        ga('send', 'event', 'button', 'click', 'bestel guide');
    });
}